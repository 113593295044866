import { PageContent, render, JSXFactory } from '@appzuka/rxnano';

import { meetingList } from '../meeting/meeting';

import { FooterLogo } from 'site/components/dharmaLogo';
import './home.scss';

const homeContent = () => {
  render(
    <div id='home-content'>
      {FooterLogo}
      <p class='mission-statement'>USING BUDDHIST PRACTICE AND PRINCIPLES TO END THE SUFFERING OF ADDICTION</p>
      <p> We are a peer-led movement using Buddhist practices and principles to overcome addiction through meditation and mindfulness, personal inquiry, and community. We provide support to local, autonomous meetings through shared literature, online resources, and connection to our international network of participants.</p>
      <p>A non-profit organization, Recovery Dharma, administers the website and other properties on behalf of the community (sangha).</p>
      <h2>Meeting Locations</h2>
      <p>Please click on a location to view available meetings:</p>
      {meetingList}
    </div>
  )
}

const homePages : PageContent = {
    content: [
        {
            pageLocation: '',
            image: () => {},
            pageTitle: 'Home',
            content: homeContent
        }
    ],
    subMenu: []
};

export {
    homePages
};

