
import {
  elementOpen,
  elementVoid,
  elementClose,
  text,
  skipNode
} from 'incremental-dom';

export var articleContent = () => {
  var el = elementOpen('div', 'elid', ['class', 'article']);
    elementOpen('h2');text(`What is Recovery Dharma?`);elementClose('h2');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('We are a peer-led movement using Buddhist practices and principles to overcome addiction through meditation and mindfulness, personal inquiry, and community. We provide support to local, autonomous meetings through shared literature, online resources, and connection to our international network of participants.')
elementClose('p');
elementOpen('p');
text('A non-profit organization, Recovery Dharma, administers the website and other properties on behalf of the community (sangha).')
elementClose('p');
elementClose('div');
elementOpen('h2');text(`What does Recovery Dharma believe?`);elementClose('h2');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('We believe that the traditional Buddhist teachings (“Dharma”) offer a powerful approach to healing from addiction and living a life of true freedom. By following a balanced path of understanding, ethical behaviour, and mindfulness, all beings have the potential to find happiness and free themselves from the suffering of repetitive craving.')
elementClose('p');
elementOpen('p');
text('Recovery Dharma is centred on the idea that meditation, the Four Noble Truths, and the support of our sangha can foster our journey toward an end to the suffering of addiction.')
elementClose('p');
elementClose('div');
elementOpen('h2');text(`Do I have to be a Buddhist to participate?`);elementClose('h2');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('While our program is based on teachings and practices that go all the way back to the time of the Buddha 2600 years ago, we believe that anyone can benefit from this wisdom regardless of whether they consider themselves a Buddhist.')
elementClose('p');
elementOpen('p');
text('These teachings, as we practice them, are fully compatible with a secular perspective. The core practices of wisdom, ethics, and mindfulness are available to all beings, regardless of their other beliefs or affiliations. Our program doesn’t require the adoption of any religious principles, only that we do the work needed for these practices to transform our lives.')
elementClose('p');
elementClose('div');
elementOpen('h2');text(`What is a Recovery Dharma meeting like?`);elementClose('h2');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('Our weekly meetings are a chance to come together to support recovery through the practice of meditation and mindfulness, study of the Dharma, listening to each other, and building community.')
elementClose('p');
elementOpen('p');
text('A typical meeting starts with a few short explanatory readings, then a guided meditation. Meetings will then typically do a shared reading to foster discussion, or a speaker may talk about a topic of their choice. There is also an opportunity for members of the group to share. Each meeting is free to choose the format that works best for them.')
elementClose('p');
elementOpen('p');
text('Meetings are always free, though members are encouraged to support any costs incurred by the meetings through dāna (donations).')
elementClose('p');
elementOpen('p');
text('Each meeting operates independently, except in matters affecting other groups or Recovery Dharma as a whole. The group’s health and well-being is of utmost importance. Personal recovery depends on connection with a healthy, safe, confidential, and stable community. We encourage groups to practice ethical behaviour in the conduct of meetings, refraining from violence, dishonesty, creating an environment of sexual discomfort or misconduct, or intoxication. How each group chooses to implement these guidelines is a matter for local discussion and decision-making.')
elementClose('p');
elementClose('div');
elementOpen('h2');text(`Are there online meetings?`);elementClose('h2');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('There are many online Recovery Dharma meetings each week! These are perfect for those who don’t have a local in-person meeting yet. For those that do have a local sangha, online groups offer a chance to supplement your practice with more meetings each week.')
elementClose('p');
elementOpen('p');
text('Online meetings use the Zoom platform which supports both video and audio for all participants, offering a very interactive and intimate experience. Zoom also supports dialing in by telephone, so no one who wants to join should be left out.')
elementClose('p');
elementOpen('p');
text('View the full list of online meetings at ')
elementOpen('a', null, ['href', 'http://recoverydharma.online']);
text('http://recoverydharma.online')
elementClose('a');
text('.')
elementClose('p');
elementClose('div');
elementOpen('h2');text(`Can I start a Recovery Dharma meeting?`);elementClose('h2');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('Yes, anyone willing to commit to the responsibilities of facilitating can start a local meeting! We are decentralized, and there are no rules to follow other than that the meeting should be an open, safe, and accessible space that tries to uphold our core principles of mindfulness, compassion, forgiveness, and generosity.')
elementClose('p');
elementOpen('p');
text('The book Dharma of Recovery has resources to help volunteers start and run meetings, including a sample meeting format, meditation scripts and more:')
elementClose('p');
elementOpen('p');
text('Click here to download in ')
elementOpen('a', null, ['href', 'http://bit.ly/DharmaRecoveryDraft']);
text('PDF format')
elementClose('a');
text(' or ')
elementOpen('a', null, ['href', 'https://docs.google.com/document/d/1FxbMZWupwPb169mTVk0wrEjVtETD25cRfeaNHAnh5-k/edit?fbclid=IwAR0lMtdDQ7dYkTBmdtXtiP3sqXL2FjE8KI51gp98OQcjDo9_pTOs2JF_L10']);
text('Google Docs format')
elementClose('a');
elementClose('p');
elementClose('div');
elementOpen('h2');text(`Does the program support addictions other than drugs and alcohol?`);elementClose('h2');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('We have found that the Buddhist wisdom and practices that make up Recovery Dharma can help us understand and overcome the suffering caused by any type of addiction, whether it’s a dependency to drugs and alcohol, or behaviour-based addictions (which we refer to as “process addictions”) like sex, relationships, food, gambling, self-harm, co-dependency, etc.')
elementClose('p');
elementOpen('p');
text('All those who seek recovery are welcome at meetings regardless of the substance or behaviour that brings them to us.')
elementClose('p');
elementClose('div');
elementOpen('h2');text(`Does Recovery Dharma require complete abstinence from drugs and alcohol?`);elementClose('h2');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('Recovery Dharma encourages full abstinence and renunciation from all recreational mind-altering substances. This is a path based on mindfulness and living an ethical life that avoids causing harm to others, both of which rely on maintaining a mind that is aware and sober whenever possible.')
elementClose('p');
elementOpen('p');
text('Sobriety is the first step toward the renunciation — letting go of our need for intoxicating experiences — that constitutes true recovery. We take this first step while recognizing that the reality of “addiction switching” means we will have to remain vigilant and mindful in our recovery to identify any new substances or behaviours that take the place of those we’ve successfully given up.')
elementClose('p');
elementOpen('p');
text('For those of us whose addictions involve things like food and technology from which complete abstinence may not be possible, renunciation will mean something different, based on thoughtful boundaries and intentions in our behaviours. For some of us, abstinence from things like sexual behaviour or seeking out love and relationships may be necessary as we work to understand and find meaningful boundaries.')
elementClose('p');
elementOpen('p');
text('All this said, you are welcome wherever you are on the path, one day sober or 10 years, and we hope that our fellowship can support you on your way to freedom.')
elementClose('p');
elementOpen('p');
text('Please note that our emphasis on abstinence does not apply to prescribed medications that people need in order to function skillfully in the world, including those that support mental health and Medication Assisted Treatment (MAT) for addiction.')
elementClose('p');
elementClose('div');
elementOpen('h2');text(`Is this a faith based program?`);elementClose('h2');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('Recovery Dharma is not a faith-based program, but we welcome people of all faiths. Our program does not require belief in a God or higher power.')
elementClose('p');
elementOpen('p');
text('We have found many people participate in Recovery Dharma because they are seeking an alternative to 12-step recovery. We also have many members who practice 12 step or other recovery programs alongside ours. These paths are not mutually exclusive and it’s up to each person to discover their own path to recovery.')
elementClose('p');
elementClose('div');
elementOpen('h2');text(`What is your relationship to other recovery programs?`);elementClose('h2');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('We do not ask anybody to give up participation in any other meeting, recovery program, or spiritual practice. Many of us combine traditional 12-step or other recovery programs with our Recovery Dharma program. Recovery Dharma is based on the idea that we each must find the path to recovery that works for us, and we are committed to supporting all who seek recovery.')
elementClose('p');
elementOpen('p');
text('We hope that our new community will continue the tradition of service and mutual support that has helped so many heal the suffering of addiction through Buddhist practice.')
elementClose('p');
elementClose('div');
  elementClose('div');
  return el;
};