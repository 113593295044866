import { PageContent, linkClick, render, JSXFactory } from '@appzuka/rxnano';

const extLink = (link) =>
    <p><a href={link} rel='noreferrer' target='_blank'>{link}</a></p>

const infoLinks = () => {
  render(<>
    <h2>Information Links</h2>
    <p><a href='/' onclick={(e) => linkClick(e, '/info/faq')}>FAQ</a></p>
    <p><a href='/' onclick={(e) => linkClick(e, '/info/subscribe')}>Subscribe</a></p>
    <p><a href='/' onclick={(e) => linkClick(e, '/info/discussion')}>Discussion</a></p>
  </>)
}

const infoPages : PageContent = {
    content: [
        {
            pageLocation: 'info',
            image: () => {},
            pageTitle: 'Information',
            content: infoLinks
        },
        {
            pageLocation: 'info/faq',
            image: () => {},
            pageTitle: 'FAQ',
            content: <>
                  <p>A message from the Recovery Dharma Transition Team</p>
                  {extLink('https://docs.google.com/document/d/1sbLV_sqhUH-wa8gE-VFq-e1tzP0J1IiToJKv80m6cB4')}
                  <h2>Recovery Dharma FAQ</h2>
                  {extLink('https://docs.google.com/document/d/1ObRP5RKFMEHWZPI8MQjUEZRPqodxSt_x3si_UqlRr9E')}
                </>
        },
        {
            pageLocation: 'info/subscribe',
            image: () => {},
            pageTitle: 'Subscribe',
            content: <>
                <h2>Subscribe to Recovery Dharma</h2>
                <p>Mailchimp Mailing List</p>
                {extLink('http://bit.ly/rd-mailing-list')}
                <p>Facebook Page</p>
                {extLink('https://www.facebook.com/RecoveryDharma')}
                <p>Instagram</p>
                {extLink('https://www.instagram.com/recoverydharma')}
                <p>Twitter</p>
                {extLink('https://twitter.com/recoverydharma')}
                </>
        },
        {
            pageLocation: 'info/discussion',
            image: () => {},
            pageTitle: 'Discussion',
            content: <>
                <h2>Recovery Dharma Discussion Groups</h2>
                <p>Facebook Group</p>
                {extLink('http://bit.ly/rd-fb-group')}
                <p>Business Sangha Facebook Group</p>
                {extLink('http://bit.ly/rd-business-fb-group')}
                <p>Task List Open Google Doc</p>
                {extLink('http://bit.ly/rdc-task-list')}
                </>
        }
    ],
    subMenu: []

}

export {
    infoPages
};

