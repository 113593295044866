import {
    patch,
    elementOpen,
    elementClose,
    text
} from 'incremental-dom';

import {
  PageListItem,
  PageContent,
  linkClick,
  notFoundPage,
  termsPages,
  render,
  JSXFactory  
} from '@appzuka/rxnano';

import { meetingPages } from 'site/pages/meeting/meeting';
import { aboutPages } from 'site/pages/about/aboutPages';
import { infoPages } from 'site/pages/info/info';
import { homePages } from 'site/pages/home/home';
// import { notFoundPage } from '@appzuka/rxnano/notfound';
import { contactForm } from '@appzuka/rxnano-contactform';

// import { contactPage } from 'pages/contact';
// import { termsPages } from '@appzuka/rxnano/pages/terms/termsPages';

import { PrivacyContacts, DropMenuContent as dropmenu } from 'site/siteContent';

import { meetingList } from './meeting/meeting';

const CommitInfo = require('virtual/commitinfo.js');

// FIXME: Fix for narrow screens
// NOTE: Only if DropMenuContent in SiteContent is a function
const siteLinks = <>
  <div class='hovermenucontent'>
    { () => (dropmenu as Function)(window.location.pathname) }
  </div>
</>

// Copy submenu into each page
const pageData = (pages) => {
    return(pages.content.map(k => {return {...k, subMenu: pages.subMenu}}))
};

// Pass in an empty function to omit the form
const siteContactForm = (pco) => contactForm(pco, {
    testMode: !CommitInfo.production || CommitInfo.test,
    sourceSite: 'www.recoverydharma.co.uk',
    highlight: '#68b0e0',
    commitVersion: CommitInfo.commit
    });

const contactPageContent = (pco) => {
    render(
      <>
        <h2>Contact</h2>
        <p>Please click on a location to view contacts for a meeting near you:</p>
        {meetingList}
        <p style='padding-top: 20px'>For questions about specific meetings please use the email or facebook contacts on the meeting page for that location.</p>
        <p>For general questions you can send a message to us using the form below and we will respond as soon as possible.</p>
        {() => siteContactForm(pco)}
      </>
    )

}    

const newContactPage : PageContent = {
    content: [{
        pageLocation:`contact`,
        image: () => {},
        pageTitle: 'Meetings',
        content: (pco) => contactPageContent(pco)
    }],
    subMenu: []
}

const pageList : PageListItem[] = [
    ...pageData(notFoundPage('banners/notfound', siteLinks)),
    ...pageData(newContactPage),
    ...pageData(homePages),
    ...pageData(aboutPages),
    ...pageData(meetingPages),
    ...pageData(infoPages),
    ...pageData(termsPages(PrivacyContacts)),
];

export {
    pageList
};
  
