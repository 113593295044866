import {
    elementOpen,
    elementVoid,
    elementClose,
    text
} from 'incremental-dom';

import './logo.scss';

export const DharmaLogo = (logoClass: string) => {

        const chars = [
            "M163.65,42.9A116.71,116.71,0,1,0,280.36,159.61C280,95.23,228,42.9,163.65,42.9Zm0,212.32a95.61,95.61,0,1,1,95.6-95.61A95.73,95.73,0,0,1,163.65,255.22Z",
            "M203.25,159.55a38.81,38.81,0,0,0-13.47-29.43c7.27,11.61,6.52,21.11,5.09,25.46-2.17,8-8.38,14.15-15.64,15.64a9,9,0,0,1-2.92.37,16.49,16.49,0,0,1-12.73-6.21c-3.66.38-13.47,1.8-14.52,9.07-.75,3.66,1.42,8,5.46,11.3s14.9,9.81,36,3.66C198.16,181.4,203.25,171.22,203.25,159.55Z",
            "M150.92,189.72c-5.46-4.72-8.38-10.93-7.26-16.7,1.8-10.93,13.84-13.47,18.93-13.84.37-4.35,0-14.53-8.75-17.08-14.16-3.66-27.63,12-29.8,15.28v2.17a39.72,39.72,0,0,0,39.61,39.61,42,42,0,0,0,13.1-2.18H174.2C161.47,197.05,154.15,192.64,150.92,189.72Z",
            "M125.84,146.82c6.52-6.21,17.44-13.1,29.43-10.18,12.35,3.29,13.84,16.7,12.72,24.34,2.92,3.29,6.52,4.71,10.56,4,5.09-1.12,9.43-5.46,11.29-11.3,2.55-9.81-2.54-21.48-14.52-32.34a40.13,40.13,0,0,0-11.61-1.8A41.14,41.14,0,0,0,125.84,146.82Z",
            "M163.65,37.06a117.32,117.32,0,0,1,22.53,2.18,15.23,15.23,0,0,0,.75-4.35,13.07,13.07,0,0,0-13.1-13.1,6.11,6.11,0,0,0-2.55.37,13.21,13.21,0,0,0,4-9.06,13.1,13.1,0,1,0-26.2,0,11.66,11.66,0,0,0,4,9.06c-.74,0-1.43-.37-2.55-.37a13.06,13.06,0,0,0-13.09,13.1,18,18,0,0,0,.74,4.72A120.23,120.23,0,0,1,163.65,37.06Zm-1.49-10.92a5.92,5.92,0,0,0,2.54-.38,12.79,12.79,0,0,0-2.54,3.67,13.94,13.94,0,0,0-2.55-3.67C160.73,25.76,161.47,26.14,162.16,26.14Z",
            "M258.51,82.51a12.83,12.83,0,0,0,9.8-12.73,13.06,13.06,0,0,0-13.09-13.1,12.41,12.41,0,0,0-12.36,9.81A105.86,105.86,0,0,1,258.51,82.51Z",
            "M314.5,146.82a11.67,11.67,0,0,0-9.06,4c0-.75.37-1.43.37-2.55A13.15,13.15,0,0,0,284,138.44a122.51,122.51,0,0,1,1.8,20.74c.37.37.74.37,1.12.74-.38,0-.75.37-1.12.75A138.16,138.16,0,0,1,284,181.4a12.46,12.46,0,0,0,8.75,3.29,13.06,13.06,0,0,0,13.1-13.1,6.07,6.07,0,0,0-.37-2.54,13.2,13.2,0,0,0,9.06,4,13.06,13.06,0,0,0,13.1-13.1A12.83,12.83,0,0,0,314.5,146.82Zm-12.72,15.27a13,13,0,0,0-3.67-2.54,13.94,13.94,0,0,0,3.67-2.55c0,.75-.38,1.43-.38,2.55A7,7,0,0,1,301.78,162.09Z",
            "M258.13,237.4a120.9,120.9,0,0,1-16.7,16.7,13,13,0,1,0,16.7-16.7Z",
            "M163.65,281.72a136.52,136.52,0,0,1-24-2.17A12.78,12.78,0,0,0,152,296.62a6.09,6.09,0,0,0,2.55-.37,13.2,13.2,0,0,0-4,9.07,13.1,13.1,0,0,0,26.2,0,11.68,11.68,0,0,0-4-9.07c.75,0,1.43.37,2.55.37a13.06,13.06,0,0,0,13.1-13.1,12.69,12.69,0,0,0-.75-4A137,137,0,0,1,163.65,281.72Zm0,10.56a6.11,6.11,0,0,0-2.55.37,13,13,0,0,0,2.55-3.66,13.62,13.62,0,0,0,2.54,3.66C165.45,292.65,164.33,292.28,163.65,292.28Z",
            "M69.1,237.4a12.54,12.54,0,0,0-8.38,12A13,13,0,0,0,85.8,254.1,151.33,151.33,0,0,1,69.1,237.4Z",
            "M42.9,137.39a11.82,11.82,0,0,0-8-2.55,13.07,13.07,0,0,0-13.1,13.1,6.07,6.07,0,0,0,.37,2.54,13.2,13.2,0,0,0-9.06-4,13.1,13.1,0,0,0,0,26.2,11.69,11.69,0,0,0,9.06-4c0,.74-.37,1.42-.37,2.54a13.06,13.06,0,0,0,13.1,13.1,15.18,15.18,0,0,0,8-2.54,109.8,109.8,0,0,1-1.8-21.79A153,153,0,0,1,42.9,137.39ZM26.2,161.78c0-.74.37-1.42.37-2.54a5.31,5.31,0,0,0-.37-2.55,13,13,0,0,0,3.66,2.55A13.83,13.83,0,0,0,26.2,161.78Z",
            "M67.67,83.93A131.13,131.13,0,0,1,83.31,67.54,12.8,12.8,0,0,0,71,58.48a12.91,12.91,0,0,0-3.29,25.45Z",
            "M173.83,111.56V70.09a94.19,94.19,0,0,0-10.18-.75,101.29,101.29,0,0,0-10.56.75v41.1a47,47,0,0,1,10.18-1.12C166.87,110.5,170.54,110.88,173.83,111.56Z",
            "M219.27,89l-29.05,29.06a46.3,46.3,0,0,1,14.52,14.53l29.06-29.06A95.06,95.06,0,0,0,219.27,89Z",
            "M212.69,159.55a41.41,41.41,0,0,1-1.12,9.81h41.1a94.19,94.19,0,0,0,.75-10.18,87.86,87.86,0,0,0-.75-10.19h-41.1A51,51,0,0,1,212.69,159.55Z",
            "M190.53,201l28.74,28.74a89.49,89.49,0,0,0,14.53-14.52l-28.75-28.75A54.69,54.69,0,0,1,190.53,201Z",
            "M153.09,207.91v40.73a94.17,94.17,0,0,0,10.18.74,87.58,87.58,0,0,0,10.18-.74V207.91a46.82,46.82,0,0,1-20.36,0Z",
            "M122.18,186.49,93.43,215.24A89.49,89.49,0,0,0,108,229.76L136.7,201A46.92,46.92,0,0,1,122.18,186.49Z",
            "M114.17,159.55A48.71,48.71,0,0,1,115.28,149H74.19a92.07,92.07,0,0,0-.75,10.19,85.54,85.54,0,0,0,.75,10.18h40.72A75,75,0,0,1,114.17,159.55Z",
            "M136.7,118.14l-29-29a89.49,89.49,0,0,0-14.53,14.52l29.06,29.06A55.58,55.58,0,0,1,136.7,118.14Z"
        ];


    elementOpen('svg', null, null,
        'class', `site-icon ${logoClass}`,
        'viewBox', '0 0 327.6 318.41'
    );
    elementOpen('title');
        text('Site Logo');
    elementClose('title');
    chars.map((c, i) => {
        elementVoid('path', `site-logo-${i}`, ['d', c]);
    });
    elementClose('svg');
}

export const DharmaText = (logoClass: string) => {

    const chars = [    
        "M48.92,27.54a28.2,28.2,0,0,1-2,10.79,25.34,25.34,0,0,1-5.68,8.55A25.58,25.58,0,0,1,32.4,52.5a31.32,31.32,0,0,1-11.45,2H0V.61H20.94a30.91,30.91,0,0,1,11.45,2,26.18,26.18,0,0,1,8.82,5.62,25.16,25.16,0,0,1,5.68,8.53A28,28,0,0,1,48.92,27.54Zm-12.8,0a23.57,23.57,0,0,0-1-7.22,15.37,15.37,0,0,0-3-5.46,12.92,12.92,0,0,0-4.75-3.44,16.19,16.19,0,0,0-6.4-1.2H12.59V44.89H21a16.16,16.16,0,0,0,6.4-1.2,12.92,12.92,0,0,0,4.75-3.44,15.44,15.44,0,0,0,3-5.46A23.59,23.59,0,0,0,36.12,27.54Z",
        "M101.23.6V54.51H88.66V31.72H66.76V54.51H54.17V.6H66.75V23.13h21.9V.6Z",
        "M157.88,54.51h-9.69a4.21,4.21,0,0,1-2.63-.76A4.78,4.78,0,0,1,144,51.81l-3.18-9.4h-20.5l-3.18,9.4a4.55,4.55,0,0,1-1.46,1.87,4.1,4.1,0,0,1-2.61.83h-9.77L124.21.6H137ZM138,33.9l-5-14.76c-.37-.94-.76-2-1.18-3.31s-.84-2.65-1.26-4.12c-.37,1.5-.76,2.9-1.18,4.18s-.81,2.39-1.18,3.33l-5,14.69H138Z",
        "M204.1,54.51H192.78a4.79,4.79,0,0,1-4.51-2.37l-9-15.65A4.3,4.3,0,0,0,177.9,35a4.37,4.37,0,0,0-2.22-.46H172.5V54.51H160V.6h17.54a36,36,0,0,1,10,1.2,18.61,18.61,0,0,1,6.73,3.35A12.65,12.65,0,0,1,198,10.22a16.78,16.78,0,0,1,1.18,6.36,17.09,17.09,0,0,1-.7,5,15.45,15.45,0,0,1-2.07,4.31,15.67,15.67,0,0,1-3.38,3.53,17.86,17.86,0,0,1-4.64,2.55,11.23,11.23,0,0,1,2.29,1.57,11.05,11.05,0,0,1,1.92,2.24ZM177.53,26a12.7,12.7,0,0,0,4.35-.65,7.81,7.81,0,0,0,2.94-1.79,6.8,6.8,0,0,0,1.66-2.68,10.44,10.44,0,0,0,.52-3.31A6.94,6.94,0,0,0,184.69,12q-2.31-2-7.16-2h-5V26Z",
        "M267.11.6V54.51h-11v-31c0-.71,0-1.49.06-2.33s.1-1.69.2-2.55L242,46.33a4.68,4.68,0,0,1-1.79,2,5,5,0,0,1-2.57.67H236a4.9,4.9,0,0,1-2.57-.67,4.68,4.68,0,0,1-1.79-2L217.28,18.54q.1,1.34.18,2.61c0,.85.07,1.63.07,2.35v31h-11V.6h9.55c.54,0,1,0,1.42,0a3.9,3.9,0,0,1,1.09.22,2.43,2.43,0,0,1,.87.57,4.57,4.57,0,0,1,.76,1.09l13.88,27.34c.49.94,1,1.91,1.42,2.92s.89,2,1.31,3.11c.42-1.08.86-2.15,1.31-3.18s.94-2,1.46-3L253.41,2.52a4.57,4.57,0,0,1,.76-1.09A2.43,2.43,0,0,1,255,.86a3.57,3.57,0,0,1,1.09-.22c.41,0,.88,0,1.42,0Z",
        "M323.75,54.51h-9.69a4.21,4.21,0,0,1-2.63-.76,4.78,4.78,0,0,1-1.52-1.94l-3.18-9.4h-20.5l-3.18,9.4a4.55,4.55,0,0,1-1.46,1.87,4.1,4.1,0,0,1-2.61.83h-9.77L290.08.6h12.8ZM303.88,33.9l-5-14.76c-.37-.94-.76-2-1.18-3.31s-.84-2.65-1.26-4.12c-.37,1.5-.76,2.9-1.18,4.18s-.81,2.39-1.18,3.33l-5,14.69h14.76Z"
       ];


elementOpen('svg', null, null,
    'class', `site-icon ${logoClass}`,
    'viewBox', '0 0 323.75 55.11'
);
elementOpen('title');
    text('Site Logo');
elementClose('title');
chars.map((c, i) => {
    elementVoid('path', `site-logo-${i}`, ['d', c]);
});
elementClose('svg');
}

export const RecoveryText = (logoClass: string) => {

    const chars = [   
        "M38.39,47.45H28.53a4.18,4.18,0,0,1-3.93-2.06L16.81,31.77a3.83,3.83,0,0,0-1.22-1.27,3.8,3.8,0,0,0-1.93-.4H10.89V47.46H0V.53H15.27A31.23,31.23,0,0,1,24,1.58,16.25,16.25,0,0,1,29.81,4.5a11.16,11.16,0,0,1,3.3,4.41,14.57,14.57,0,0,1,1,5.54,14.84,14.84,0,0,1-.61,4.32,13.52,13.52,0,0,1-1.8,3.75,13.84,13.84,0,0,1-2.95,3.08,15.85,15.85,0,0,1-4,2.22,10.15,10.15,0,0,1,2,1.37,9.64,9.64,0,0,1,1.67,1.95ZM15.27,22.59A11.32,11.32,0,0,0,19.05,22a6.93,6.93,0,0,0,2.56-1.56,5.91,5.91,0,0,0,1.45-2.33,9,9,0,0,0,.45-2.88,6,6,0,0,0-2-4.83q-2-1.74-6.23-1.74H10.89V22.61h4.38Z",
        "M51.76,8.9v11H66.51v8H51.76V39.08H71v8.37H40.81V.52H71V8.89H51.76Z",
        "M106.84,35.31a2.28,2.28,0,0,1,.77.14,2.11,2.11,0,0,1,.74.5l4.32,4.54a18,18,0,0,1-7.1,5.59A24.81,24.81,0,0,1,95.47,48a24.1,24.1,0,0,1-9.58-1.82,20.75,20.75,0,0,1-7.18-5,22.28,22.28,0,0,1-4.53-7.6A28.1,28.1,0,0,1,72.6,24a26.41,26.41,0,0,1,1.71-9.65,22.53,22.53,0,0,1,4.8-7.59,21.65,21.65,0,0,1,7.41-5A24.8,24.8,0,0,1,96,0a25.52,25.52,0,0,1,5,.47,23.83,23.83,0,0,1,4.36,1.29,20.45,20.45,0,0,1,3.74,2,19.44,19.44,0,0,1,3,2.51l-3.67,4.93a5,5,0,0,1-.84.82,2.15,2.15,0,0,1-1.35.37,2.34,2.34,0,0,1-1.1-.26,12,12,0,0,1-1.1-.63l-1.27-.8a10,10,0,0,0-1.64-.81,12.93,12.93,0,0,0-2.22-.63A15.29,15.29,0,0,0,96,9,11.92,11.92,0,0,0,91,10a11,11,0,0,0-3.87,2.91,13.62,13.62,0,0,0-2.51,4.7A21,21,0,0,0,83.75,24a19.44,19.44,0,0,0,1,6.41,14.12,14.12,0,0,0,2.67,4.72A11.36,11.36,0,0,0,91.35,38a11.7,11.7,0,0,0,4.83,1,22.45,22.45,0,0,0,2.71-.15,13.05,13.05,0,0,0,2.29-.48,9.48,9.48,0,0,0,2-.9,12.76,12.76,0,0,0,1.9-1.43,4.1,4.1,0,0,1,.84-.53A2.09,2.09,0,0,1,106.84,35.31Z",
        "M160.85,24a25.24,25.24,0,0,1-1.75,9.47,22.93,22.93,0,0,1-4.94,7.62,22.66,22.66,0,0,1-7.68,5.07,28,28,0,0,1-19.95,0,22.39,22.39,0,0,1-12.67-12.69A25.24,25.24,0,0,1,112.1,24a25,25,0,0,1,1.76-9.47,22.49,22.49,0,0,1,5-7.6,23.07,23.07,0,0,1,7.71-5.06,26.31,26.31,0,0,1,10-1.84,26,26,0,0,1,10,1.85,22.91,22.91,0,0,1,7.68,5.07,22.59,22.59,0,0,1,4.94,7.6A25.28,25.28,0,0,1,160.85,24Zm-11.14,0a20.26,20.26,0,0,0-.9-6.26A13.36,13.36,0,0,0,146.22,13a11.12,11.12,0,0,0-4.14-3,14.19,14.19,0,0,0-5.57-1,14.4,14.4,0,0,0-5.62,1,11.1,11.1,0,0,0-4.15,3,13.36,13.36,0,0,0-2.59,4.72,20.35,20.35,0,0,0-.9,6.26,20.48,20.48,0,0,0,.9,6.3A13.36,13.36,0,0,0,126.74,35a11,11,0,0,0,4.15,2.95,16,16,0,0,0,11.19,0A11.09,11.09,0,0,0,146.22,35a13.2,13.2,0,0,0,2.59-4.72A20.48,20.48,0,0,0,149.71,24Z",
        "M206.1.53,187.29,47.46h-9.86L158.62.53h8.76a3.7,3.7,0,0,1,2.29.66A4.16,4.16,0,0,1,171,2.88L180.07,28c.43,1.09.85,2.29,1.26,3.59s.79,2.66,1.16,4.07c.3-1.42.64-2.77,1-4.07s.78-2.5,1.21-3.59l9-25.12A4,4,0,0,1,195,1.25a3.51,3.51,0,0,1,2.27-.72Z",
        "M219.21,8.9v11H234v8H219.21V39.08h19.26v8.37H208.26V.52h30.21V8.89H219.21Z",
        "M282,47.45h-9.86a4.18,4.18,0,0,1-3.93-2.06l-7.79-13.62a3.83,3.83,0,0,0-1.22-1.27,3.78,3.78,0,0,0-1.93-.4h-2.77V47.46H243.63V.53H258.9a31.23,31.23,0,0,1,8.68,1.05,16.25,16.25,0,0,1,5.86,2.92,11.16,11.16,0,0,1,3.3,4.41,14.53,14.53,0,0,1,1,5.54,14.84,14.84,0,0,1-.61,4.32,13.39,13.39,0,0,1-1.8,3.75,13.84,13.84,0,0,1-2.95,3.08,15.85,15.85,0,0,1-4,2.22,10.15,10.15,0,0,1,2,1.37A9.64,9.64,0,0,1,272,31.14ZM258.9,22.59a11.43,11.43,0,0,0,3.79-.56,7,7,0,0,0,2.56-1.56,5.91,5.91,0,0,0,1.45-2.33,9,9,0,0,0,.45-2.88,6,6,0,0,0-2-4.83q-2-1.74-6.23-1.74h-4.38V22.61h4.37Z",
        "M304.41,29.61V47.45H293.52V29.61L276.51.53h9.6a3.48,3.48,0,0,1,2.24.66,5.36,5.36,0,0,1,1.37,1.69l6.64,13.66c.56,1.07,1.06,2.08,1.51,3s.85,1.85,1.19,2.75q.48-1.38,1.11-2.79c.42-.93.91-1.93,1.47-3l6.57-13.66a5.74,5.74,0,0,1,.55-.84,4.21,4.21,0,0,1,.76-.76,3.6,3.6,0,0,1,2.24-.76h9.66Z",

    ];

elementOpen('svg', null, null,
    'class', `site-icon ${logoClass}`,
    'viewBox', '0 0 321.42 47.97'
);
elementOpen('title');
    text('Site Logo');
elementClose('title');
chars.map((c, i) => {
    elementVoid('path', `site-logo-${i}`, ['d', c]);
});
elementClose('svg');
}

export const HeaderLogo = () => {
    elementOpen('div', 'header-logo', ['class', 'header-logo']);
        DharmaLogo('logo-img');
        elementOpen('div', 'header-logo-text', ['class', 'header-logo-text']);
            RecoveryText('logo-text1');
            DharmaText('logo-text2');
        elementClose('div');
    elementClose('div');
}

export const FooterLogo = () => {
    elementOpen('div', 'footer-logo', ['class', 'footer-logo']);
        DharmaLogo('logo-img');
        elementOpen('div', 'footer-logo-text', ['class', 'footer-logo-text']);
            RecoveryText('logo-text1');
            DharmaText('logo-text2');
        elementClose('div');
    elementClose('div');
}

export const HomeLogo = () => {
    elementOpen('div', 'home-logo', ['class', 'home-logo']);
        DharmaLogo('logo-img');
        elementOpen('div', 'home-logo-text', ['class', 'home-logo-text']);
            RecoveryText('logo-text1');
            DharmaText('logo-text2');
        elementClose('div');
    elementClose('div');
}

