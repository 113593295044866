import { PageContent } from '@appzuka/rxnano';

import { articleContent as aboutContent } from './about.md'

const aboutPages : PageContent = {
    content: [
        {
            pageLocation: 'about',
            image: () => {},
            pageTitle: 'About',
            content: aboutContent
        }
    ],
    subMenu: []

}

export {
    aboutPages
};

