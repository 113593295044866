import {
    elementOpen,
    elementClose,
    elementVoid,
    text,
    patch
} from 'incremental-dom';

import { PageContent, PageListItem, sitePix, linkClick, mapLoadedObservable, render, JSXFactory } from '@appzuka/rxnano';

import { SiteData } from 'site/siteContent';

// import { articleContent as aboutContent } from './about.md'

import { Locations } from './meetingData';
import { first } from 'rxjs/internal/operators/first';

import './meeting.scss';

const meetingList = (current?) => {
  render(
    <div class='location-list-wrapper'>
      {() => Object.keys(Locations)
        .filter( l => l !== current)
        .map(l => {
        return(
          <a class='location-thumb-link' href={`/meetings/${l}`} onclick={(e) => linkClick(e, `/meetings/${l}`)}>
            <div class='location-thumb' >
              { () => sitePix(SiteData.assetBase, `thumbnails/${l}`, { mediaSizes: '(max-width: 649px) 33vw, (max-width: 1023px) 25vw, 20vw'}) }
              <h2>{Locations[l].city}</h2>
            </div>
          </a>)
      }).reduce((f, x) => x() , undefined)}
  </div>
  )
}

const meetingContent = (location) => {

    const locationData = Locations[location];

    elementOpen('h2');
        text('Contact Details');
    elementClose('h2');

    locationData.contacts.map((c, ci) => {
        elementOpen('div', `contact-${ci}`, ['class', 'meeting-contact']);
            elementOpen('p');
                text(c.type)
            elementClose('p');
            if (c.link) {
              elementOpen('a', null, [
                'href', c.link,
                'target', c.link.substring(0, 7)==="mailto:"?'_self':'_blank'
                ]);
                text(c.display)
              elementClose('a');
            } else {
              elementOpen('p');
                text(c.display)
              elementClose('p');
            }

        elementClose('div');
    });

    if (location === 'london') {
      render(<>
        <p>Follow our WhatsApp Channel to keep up to date with all the weekly meetings and any changes: <a href='https://whatsapp.com/channel/0029VaRP7vN2UPBCyHXWaM17'>https://whatsapp.com/channel/0029VaRP7vN2UPBCyHXWaM17</a></p>
        <p>There are two in-person meetings.  Scroll down for details.</p>
      </>);
      zoomMeetingInfo();
    }

    if (location === 'bristol') {
      render(<div>
      <p>Problem with alcohol, drugs, sex, gambling? </p>
      <p>All of them and more?</p>
      <p>There is more than one path to recovery</p>
      <ul>
        <li>Secular: No beliefs required</li>
        <li>Peer-led: We've all been there</li>
        <li>Truly open-minded: We make no difference between substance and process addictions</li>
        <li>Effective: Empower yourself through a structured meditation and self-inquiry programme</li>
      </ul>
      </div>);
    }

    if (location === 'liverpool') {
      render(<div>
        <p>We have two weekly online meetings (the login is the same for both): Zoom Id: 558 318 4669, password 12345.</p>

<ul>
  <li>Saturday: 7pm: RD Liverpool Online: <a href='https://us06web.zoom.us/j/5583184669'>https://us06web.zoom.us/j/5583184669</a>; Passcode: 12345</li>
  <li>Tuesday: 1pm: RD Liverpool Online: <a href='https://us06web.zoom.us/j/5583184669'>https://us06web.zoom.us/j/5583184669</a>; Passcode: 12345</li>
</ul>
      </div>);
    }



    locationData.meetings.map((m, mi) => {

      render(
        <div class='meeting-wrapper'>
          <div class='meeting'>
            <h2>Meeting Details</h2>
            <div id='meeting-details'>
              {m.headnote?<p class="headnote" dangerouslySetInnerHTML={m.headnote}></p>:''}
              <p>{m.date}</p>
              <p>{m.place}</p>
              <p>{m.address}</p>
              {m.note.length>0?<p>{m.note}</p>:''}
            </div>
            {m.alternate?
            <div class='alternatemeeting'>
              <h2 class="alternate">Alternate Venue Details</h2>
              <p class="alternate">{m.alternate.date}</p>
              <p>{m.alternate.place}</p>
              <p>{m.alternate.address}</p>
              {m.alternate.note.length>0?<p>{m.alternate.note}</p>:''}
            </div>:''
            }
          </div>
          <div id={`meetingmap-${mi}`} class='meeting-map'></div>
      </div>
      );
      // if (m.htmlNote) {
      //   const el = document.getElementById('meeting-details');
      //   const pel = document.createElement("div");
      //   pel.innerHTML = m.htmlNote;
      //   el!.appendChild(pel);
      // }
      
    });

    elementOpen('h2');
        text('Other Meeting Locations');
    elementClose('h2');

    meetingList(location);

    const loadMap = (MapInfo) => {

        mapLoadedObservable
        .pipe(
            first(v => v === true),
        )
        .subscribe(() => {

            MapInfo.map((map, mi) => {

                const myLatlng = new google.maps.LatLng(map.lat, map.lon);
                const mapOptions = {
                    zoom: 14,
                    center: myLatlng,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                };
                const mapElement = document.getElementById(`meetingmap-${mi}`);
                if (mapElement !== null) {
                    const gmap = new google.maps.Map(mapElement, mapOptions);
                    new google.maps.Marker({
                        position: myLatlng,
                        map: gmap,
                        title: MapInfo.title
                    });
                }  
            });
        });
    };

    const hh = locationData.meetings.map((m, mi) => {
        return ({
                    lat: m.lat, 
                    lon: m.lon,
                })
    });

    if (document.getElementById('fixture') === null) {
        // This is not a karma test
        loadMap(hh)
    };

}

const meetingPages1 : PageListItem[]  = Object.keys(Locations).map(l => {
        return ({
            pageLocation:`meetings/${l}`,
            image: {imageName: `banners/${l}`, title: Locations[l].city},
            pageTitle: Locations[l].city,
            content: () => meetingContent(l)
        })
    });

const zoomMeetingInfo = () => {
      render(<>

              <p>There are currently 2 online meetings as listed below. All of the meeting times listed below are current UK time.</p>
        <ul>
            <li>The Sunday Zoom meeting: 6:30 pm - 8:00 pm</li>
            <li>Tuesdays Online: 7:00 pm - 8:15 pm</li>
        </ul>
        <p> Well before the meeting, go to <a href='http://zoom.us' rel='noreferrer' target='_blank'>http://zoom.us</a> and create a free account. To protect your anonymity DO NOT log in via Facebook or Google, even if the site suggests this, just use your first name and first letter of your last name, e.g., “John T".  A few minutes before it is time for the meeting to start, go to <a href='http://zoom.us' rel='noreferrer' target='_blank'>http://zoom.us</a> click on “join meeting,” and enter meeting ID: 271 644 6105 or click <a href='https://us02web.zoom.us/j/2716446105?pwd=NktZVGhXdEp6bDFVNlRNekd5eEVJUT09' rel='noreferrer' target='_blank'>https://us02web.zoom.us/j/2716446105?pwd=NktZVGhXdEp6bDFVNlRNekd5eEVJUT09</a></p>
        <p>Password: 1234</p>
        <p> These are online video meetings which use a free web conferencing system, so all you need is any PC, Mac, iPhone, smartphone, iPad or tablet with a camera, microphone and speakers attached and turned on to participate. Please note: It is requested that all participants keep their video enabled during meetings, are appropriately dressed with adequate background lighting.</p></>)};


const meetingIndexPage = () => {
    render(
      <>
        {() => meetingList('xx')}
        <h2>Meetings</h2>

        { zoomMeetingInfo }


        {/* <p>Please click on a location to view available meetings:</p>

        <h2>Online Meetings</h2>
        <h3>Tuesdays 7:00 pm to 8:15 pm (UK Time)</h3>
        <p> Well before the meeting, go to <a href='http://zoom.us' rel='noreferrer' target='_blank'>http://zoom.us</a> and create a free account. To protect your anonymity DO NOT log in via Facebook or Google, even if the site suggests this, just use your first name and first letter of your last name, e.g., “John T".  A few minutes before it is time for the meeting to start, go to <a href='http://zoom.us' rel='noreferrer' target='_blank'>http://zoom.us</a> click on “join meeting,” and enter meeting ID: 950-488-1771 or click <a href='https://zoom.us/j/9504881771' rel='noreferrer' target='_blank'>https://zoom.us/j/9504881771</a></p>
        <p>Password: 1234</p>
        <p> These are online video meetings which use a free web conferencing system, so all you need is any PC, Mac, iPhone, smartphone, iPad or tablet with a camera, microphone and speakers attached and turned on to participate. Please note: It is requested that all participants keep their video enabled during meetings, are appropriately dressed with adequate background lighting.</p> */}
      </>
    )
}    

const meetingPages : PageContent = {
    content: meetingPages1.concat({
        pageLocation:`meetings`,
        image: () => {},
        pageTitle: 'Meetings',
        content: meetingIndexPage,
        name: 'meeting-index'
    }),
    subMenu: []
};


export {
    meetingPages,
    meetingList,
    zoomMeetingInfo
};

