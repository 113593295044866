import { packageVersion as rxnanoContactFormPackageversion } from '@appzuka/rxnano-contactform';
import {
  initApp,
  packageVersion as rxnanoBasePackageversion,
  triggerPageChange,
  preloadMaps,
  rxnanoComponent
} from '@appzuka/rxnano';
import { pageList } from './site/pages/pages';

const CommitInfo = require('virtual/commitinfo.js');

import { HeadMenuContent, DropMenuContent, SideMenuContent, HeaderSiteLogo,
    FooterContacts, FooterAddress, FooterSiteLogo, SiteData
} from './site/siteContent';

import './site/fonts.scss';

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://8e78e17c8ae64a5aa37bfc1beab47859@o442790.ingest.sentry.io/5415367',
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 1.0,
  release: "dharma@004",
});

//Ensures there will be no 'console is undefined' errors
window.console = window.console || (function(){
  var c:{[key: string]:any} = {}; c.log = c.warn = c.debug = c.info = c.error = c.time = c.dir = c.profile = c.clear = c.exception = c.trace = c.assert = function(){};
  return c as Console;
})();

if (!history.pushState) {
  // console.log('Polyfilling pushState');
  history.pushState = () => {
    // console.log('Fake pushState');
  }
}

var lastTime = 0;
var vendors = ['ms', 'moz', 'webkit', 'o'];
for(var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
    window.requestAnimationFrame = window[vendors[x]+'RequestAnimationFrame'];
    window.cancelAnimationFrame = window[vendors[x]+'CancelAnimationFrame'] 
                                || window[vendors[x]+'CancelRequestAnimationFrame'];
}

if (!window.requestAnimationFrame) {
  // console.log('Polyfilling requestAnimationFrame');
  window.requestAnimationFrame = function(callback :FrameRequestCallback) {
    var currTime = new Date().getTime();
    var timeToCall = Math.max(0, 16 - (currTime - lastTime));
    var id = window.setTimeout(function() { callback(currTime + timeToCall); }, 
      timeToCall);
    lastTime = currTime + timeToCall;
    return id;
  };
}

if (!window.cancelAnimationFrame) {
  window.cancelAnimationFrame = function(id) {
    clearTimeout(id);
  };
}

let pageChangeObservable = initApp(pageList, SiteData);

// div already present in html
// only inserted once
// NICE: Could be delayed until after main render
rxnanoComponent.insertFooter(FooterContacts, FooterAddress, FooterSiteLogo, [CommitInfo.server, CommitInfo.commit].join(' '), CommitInfo.builddate,
  ["dharma/"+CommitInfo.commit, "rxnano/"+rxnanoBasePackageversion, "contactform/"+rxnanoContactFormPackageversion], { copyrightYear: '2024' });

const wide = document.body.clientWidth>1023;
const DM = wide ? rxnanoComponent.DropMenu(DropMenuContent) : rxnanoComponent.SideMenu(SideMenuContent); 

// NICE: Could set up drop menu after main render.  Would need to show hamburger icon during first render
rxnanoComponent.HeadMenu(pageChangeObservable, HeadMenuContent, HeaderSiteLogo, DM);
triggerPageChange(null);

rxnanoComponent.ScrollMenu(pageChangeObservable, HeadMenuContent, HeaderSiteLogo, DM);  // Once installed, the scroll menu handles itself

// Preload the maps api if required on the contact page
preloadMaps(5000);

import './site/pages/overrides.scss'; // Overrides last
