import {
    elementOpen,
    elementVoid,
    elementClose,
    text
} from 'incremental-dom';

import {
  linkClick,
  ContactType,
  rxnanoIcons,
  JSXFactory,
  render
} from '@appzuka/rxnano';

// import { linkClick } from '@appzuka/rxnano/rxjs';
// import { emailIcon } from '@appzuka/rxnano/common/icons/email';
// import { linkedinIcon } from '@appzuka/rxnano/common/icons/linkedin';
// import { phoneIcon } from '@appzuka/rxnano/common/icons/phone';
// import { worldIcon } from '@appzuka/rxnano/common/icons/world';
// import { mailIcon } from '@appzuka/rxnano/common/icons/mail';
// import { twitterIcon } from 'common/icons/twitter';
// import { instagramIcon } from 'common/icons/instagram';
// import { facebookIcon } from 'common/icons/facebook';
// import { locationIcon } from '@appzuka/rxnano/common/icons/location';

// import { ContactType } from '@appzuka/rxnano/enums';

import { ISiteData } from "rxnanoModels";
const CommitInfo = require('virtual/commitinfo.js');

import { HeaderLogo, FooterLogo } from 'site/components/dharmaLogo';

// import { type } from 'os';

interface FooterContactLine {
    type: ContactType,
    icon: any,
    label: string;
    target: string
}

type FooterContactArray  = FooterContactLine[];

export const MapsAPIKey = 'AIzaSyAWGm7lJKidOYOZwusjGXLXgJApnXf5XQ8';

export const FooterContacts : FooterContactArray = [
    {type: ContactType.Link, icon: rxnanoIcons.worldIcon, label: 'recoverydharma.co.uk', target: '/'},
    // {type: ContactType.Mail, icon: emailIcon, label: 'info@recoverydharma.co.uk', target: 'info@recoverydharma.co.uk'}
];

export const FooterAddress : FooterContactArray = [

];

export const HeaderSiteLogo =  HeaderLogo;

export const FooterSiteLogo = FooterLogo;

interface HeadMenuItem {
    menuText: string
    link: string
    priority: number
}

type HeadMenuArray = HeadMenuItem[];
type DropMenuArray = HeadMenuArray[];

const defineMenuItem = (item:string, priority: number, link?:string) : HeadMenuItem  =>
  ({menuText: item, priority: priority, link: link===undefined?'/'+item.toLowerCase():link});

export const PageLabels = {
    'about': 'about us'
};

export const HeadMenuContent : HeadMenuArray = [
    defineMenuItem('About', 5),
    defineMenuItem('Meetings', 2),
    defineMenuItem('Information', 4, '/info'),
    defineMenuItem('Contact', 3)
];

export const TopMenuContent = HeadMenuContent;

const DropMenuContentArray: DropMenuArray = [
    [
        defineMenuItem('About Us', 1, '/about'),
        defineMenuItem('Home', 1, '/'),
        defineMenuItem('Contact', 1),
        defineMenuItem('Privacy', 1, '/policies/privacy'),
        defineMenuItem('Terms', 1, '/policies/terms')
    ],
    [
        defineMenuItem('UK Meetings', 1, '/meetings'),
        defineMenuItem('Brighton', 1, '/meetings/brighton'),
        defineMenuItem('Liverpool', 1, '/meetings/liverpool'),
        defineMenuItem('London', 1, '/meetings/london'),
        defineMenuItem('Oxford', 1, '/meetings/oxford'),
        defineMenuItem('Portsmouth', 1, '/meetings/portsmouth'),
        defineMenuItem('Weston-super-Mare', 1, '/meetings/weston'),
    ],
    [
        defineMenuItem('Information', 1, '/info'),
        defineMenuItem('About Recovery Dharma', 1, '/info/faq'),
        defineMenuItem('Subscribe to RD', 1, '/info/subscribe'),
        defineMenuItem('Discussion Groups', 1, '/info/discussion')
    ]
];

type vf = (string?) => void;

export const DropMenuContent: vf = (currentLocation?: string) => {
  DropMenuContentArray.map((c, j) => {
    render(<div class='hovermenucolumn'>
      <ul>
        {() => c.map(({ menuText, link }, i) => {
          let classList, finalMenuText;
          if (menuText[0] === '*') {
            classList = 'other-link';
            finalMenuText = menuText.slice(1);
          } else {
            classList = currentLocation === link ? 'active-link' : 'other-link';
            finalMenuText = menuText;
          }
          render(<li>
            <a href={link} class={classList}
               onclick={(event) => {
                linkClick(event, link);
              }}>
              {(finalMenuText.length === 0) ? ' ' : finalMenuText}
            </a>
          </li>)
        })}
      </ul>
    </div>)
  });
}

type SideMenuArray = Array<Array<Array<string>>>;

const SideMenuContentArray: SideMenuArray = [
    [
        ['About Us', '/about'],
        ['Home', '/'],
        ['Contact', '/contact'],
        ['Privacy', '/policies/privacy'],
        ['Terms', '/policies/terms']
    ],
    [
        ['UK Meetings', '/meetings'],
        ['Brighton', '/meetings/brighton'],
        ['Liverpool', '/meetings/liverpool'],
        ['London', '/meetings/london'],
        ['Oxford', '/meetings/oxford'],
        ['Portsmouth', '/meetings/portsmouth'],
        ['Weston-super-Mare', '/meetings/weston'],
    ],
    // [
    //     ['Other Meetings', '/meetings'],
    //     ['Online', '/meetings/online'],
    //     ['Worldwide', '/meetings/wolrdwide'],
    //     ['Update Tools', '/meetings/update']
    // ],
    [
        ['Information', '/info'],
        ['About Recovery Dharma', '/info/faq'],
        ['Subscribe to RD', '/info/subscribe'],
        ['Discussion Groups', '/info/discussion']
    ]
];

// TODO: If the link us is the same the menu does not close
export const SideMenuContent: SideMenuArray | vf = (currentLocation?: string) => {
  SideMenuContentArray.map((menuColumn, j) => {
    render(
    <div class='sidemenucolumn'>
      <ul>
      { () => menuColumn.map(([menuText, link], i) => {
        render(
        <li>
          <a href={link}
             class={((currentLocation === link) && ((j === 0) || (i === 0))) ? 'active-link' : 'other-link'}
             onclick={(event) => { linkClick(event, link) }}>
            {menuText}
          </a>
        </li>)
      }) }
      </ul>
    </div>)
    });
}

interface ContactPageItem {
    icon: any,
    label: string,
    linkText: string,
    link?: string
}

type ContactPageArray = ContactPageItem[];

const defineContactPageItem = (icon: any, label:string, linkText:string, link?:string) : ContactPageItem  =>
  ({icon: icon, label: label, linkText: linkText, link: link});

const londonAddress = {
    title: null,
    mapinfo: {
        latitude: 51.535708,
        longitude: -0.120786,
        zoom: 8,
        title: 'Us'
    },
    details: [
        defineContactPageItem(rxnanoIcons.locationIcon, 'Address', 'London, UK'),
        defineContactPageItem(rxnanoIcons.worldIcon, 'Web', 'www.recoverydharma.co.uk', '/'),
        defineContactPageItem(rxnanoIcons.emailIcon, 'eMail', 'info@recoverydharma.co.uk', 'mailto:info@recoverydharma.co.uk'),
    ]
}

export const ContactAddressList = [
    () => {},
    londonAddress
]

export const SiteData:ISiteData = {
    // Disable ga temporarily
    // ga: window.location.hostname==='www.recoverydharma.co.uk'?'UA-47087919-10':null,
    ga: null,
    pageTitleBase: 'Recovery Dharma',
    assetBase: 'https://appzuka-web.s3.eu-west-2.amazonaws.com/dharma/imagestream/',
    fullpixlist: 'fullpixlist.json',
    index: CommitInfo.index,
    siteName: 'www.recoverydharma.co.uk',
    highlight: '#f28b32', // FIXME
    pageLabels: {
        '/about': 'about us',
    },
    cognitoConfig: {
        region: 'eu-west-1',
        clientID: '28toug1d6udh9ipmk1cbejd07q',
        userpool: 'eu-west-1_ktnvCHUat',
        identityPool: 'eu-west-1:72ed9f40-bf99-4692-960d-818c47b16351'
    }
}
export const PrivacyContacts = {
    company: 'Recovery Dharma UK',
    policyDate: 'July 2019',
    contact: 'info@recoverydharma.co.uk'
}


