export const Locations = {
    'brighton': {
      city: 'Brighton',
      contacts: [
        {type: 'email', display: 'recoverydharmabrighton@gmail.com', link: 'mailto:recoverydharmabrighton@gmail.com'},
        {type: 'facebook', display: 'Recovery Dharma Brighton', link: 'https://www.facebook.com/groups/294734637566729/'},
      ],
      meetings: [
        {
          date: 'Thursday - 7:15 pm',
          place: 'Cornerstone Community Centre',
          note: '',
          lat: 50.82693, 
          lon: -0.16501,
          address: 'Church Road, Hove, BN3 2FL',
          // headnote: 'We now have just one weekly in-person meeting, which has been moved to Cornerstone. The meeting lasts 60 minutes. '
        }
      ]

    },


    // 'bristol': {
    //   city: 'Bristol',
    //   contacts: [
    //     {type: 'email', display: 'bristol.recoverydharma@gmail.com', link: 'mailto:bristol.recoverydharma@gmail.com'},
    //     {type: 'phone', display: '07444 376892'},
    //    ],
    //   meetings: [
    //     {
    //       date: 'Every Friday from 19:00 to 20:00',
    //       place: 'St Werburghs Community Centre',
    //       headnote: '** Starting 14 January 2022 **',
    //       lat: 51.46838, 
    //       lon: -2.57187,
    //       address: 'Horley Rd, St Werburgh\'s, Bristol BS2 9TJ',
    //       note: '',
    //       htmlNote: '<p>Problem with alcohol, drugs, sex, gambling? </p><p>All of them and more?</p><p>There is more than one path to recovery:</p><ul><li>Secular: no beliefs required</li><li>Peer-led: we\'ve all been there;</li><li>Truly open-minded: we make no difference between substance and process addictions;</li><li>Effective: empower yourself through a structured meditation and self-inquiry programme.</li></ul><p>Phone: 07444 376 892</p>'
    //     }
    //   ]
    // },
    'bognor': {
      city: 'Bognor Regis',
      contacts: [
        {type: 'email', display: 'recoverydharmabognorregis@gmail.com', link: 'recoverydharmabognorregis@gmail.com'},
        {type: 'facebook', display: 'Recovery Dharma Bognor Regis', link: 'https://www.facebook.com/recoverydharmabognorregis/'},
      ],
      meetings: [
        {
          date: 'Wednesday evenings at 7pm',
          place: 'Stonepillow Bognor Regis Hostel',
          // headnote: '** In person meeting currently suspended **',
          lat: 50.78383, 
          lon: -0.68631,
          address: '15 Ellasdale Rd, Bognor Regis PO21 2SG',
          note: ''
        }
      ]
    },
    'derby': {
      city: 'Derby',
      contacts: [
        {type: 'phone', display: '07826 533984', link: 'tel:07826 533984'},
        {type: 'email', display: 'andrew.fountain2@nhs.net', link: 'mailto:andrew.fountain2@nhs.net'},
        {type: 'www', display: 'https://www.phoenix-futures.org.uk', link: 'https://www.phoenix-futures.org.uk'},
      ],
      meetings: [
        {
          date: 'Every Friday at noon to 1pm',
          place: 'Boyer Street Community Centre',
          headnote: '** The meeting venue is subject to change in the next few months. **',
          lat: 52.913607311954436, 
          lon: -1.4881887766808992,
          address: 'Boyer Street, Derby, DE22 3XD',
          note: 'The meeting format is RD Book Study, with 10 min meditation at the start. Then around the room check in.'
        }
      ]
    },
    // 'littlehampton': {
    //   city: 'Littlehampton',
    //   contacts: [
    //     {type: 'email', display: 'rd.littlehampton@gmail.com', link: 'mailto:rd.littlehampton@gmail.com'},
    //     // {type: 'facebook', display: 'Recovery Dharma Liverpool', link: 'https://www.facebook.com/recoverydharmaliverpooluk/'},
    //   ],
    //   meetings: [
    //     {
    //       date: 'Monday evenings 7:30pm to 9pm',
    //       place: 'Dove Lodge',
    //       headnote: '** New meeting starting 1 November **',
    //       lat: 50.80726,
    //       lon: -0.53840,
    //       address: 'Beach Road, Littlehampton BN17 5JG',
    //       note: ''
    //     }

    //   ]
    // },
    // 'liverpool': {
    //   city: 'Liverpool',
    //   contacts: [
    //     {type: 'email', display: 'rdliverpooluk@gmail.com', link: 'mailto:rdliverpooluk@gmail.com'},
    //     {type: 'facebook', display: 'Recovery Dharma Liverpool', link: 'https://www.facebook.com/recoverydharmaliverpooluk/'},
    //   ],
    //   meetings: [
    //     {
    //       date: 'Tuesday- 7:00pm to 8:30pm',
    //       place: 'SHARP Community Recovery  (Top floor- Wisdom Room)',
    //       headnote: '** In person meeting currently suspended **',
    //       lat: 53.402219,
    //       lon: -2.973032,
    //       address: '17 Rodney Street, Liverpool, Merseyside, UK, L1 9EF',
    //       note: ''
    //     },
    //     {
    //       date: 'Saturday- 7:00 pm to 8:00 pm',
    //       place: 'SHARP Community Recovery  (Top floor- Wisdom Room)',
    //       headnote: '** In person meeting currently suspended **',
    //       lat: 53.402219,
    //       lon: -2.973032,
    //       address: '17 Rodney Street, Liverpool, Merseyside, UK, L1 9EF',
    //       note: ''
    //     },
    //   ]
    // },
    'london': {
      city: 'London',
      contacts: [
        {type: 'email', display: 'recoverydharmalondon@gmail.com', link: 'mailto:recoverydharmalondon@gmail.com'},
        {type: 'facebook', display: 'Recovery Dharma London', link: 'https://www.facebook.com/groups/1925750974322609'},
        {type: 'PayPal', display: 'Recovery Dharma London PayPal', link: 'https://paypal.me/RecoveryDharma131'},
      ],

      
      meetings: [
        {
          date: 'Thursdays - 6.30pm-8pm',
          place: 'North London Buddhist Centre',
          lat: 51.54947,
          lon: -0.10763,
          address: '72 Holloway Rd, London, N7 8JG',
          // headnote: '** Note: Closed: 28th December 2023 & 4th January 2024 **',
          note: '(nearest tubes: Highbury & Islington or Holloway Road)'
        },
        // {
        //   date: 'Sunday – 6:30 pm to 8:00 pm',
        //   place: 'Jamyang Buddhist Centre',
        //   lat: 51.491776,
        //   lon: -0.105363,
        //   address: 'The Old Courthouse, 43 Renfrew Road, London SE11 4NA',
        //   headnote: '** In person meeting currently suspended **',
        //   note: ''
        //   // note: '** Note: When the Jamyang Centre is closed the meeting will be held at the alternate venue.  See below for dates.',
        //   // alternate: {
        //   //   date: 'April 21, 28, May 5, July 7, 14, 21 and 28 Only',
        //   //   place: 'Bonnington Centre Community Association, Middle Room',
        //   //   lat: 51.484649,
        //   //   lon: -0.120151,
        //   //   address: '11 Vauxhall Grove, London, SW8 1TD',
        //   //   note: ''
        //   // }
        // },
        {
          // headnote: `** Note: Xmas changes: On December 4th, 11th, and 18th, the meeting will be at Waterloo Action Centre, 14 Baylis Rd, London SE1 7AA.
          // <br />
          // <br />No meeting: 25th December, 1st Jan & 8th Jan.
          // <br />
          // <br />We return to the Jamyang Centre on 15th January 2024 **`,
          date: 'Monday - 6:15 pm to 7:45 pm',
          place: 'Jamyang Buddhist Centre',
          lat: 51.491776,
          lon: -0.105363,
          address: 'The Old Courthouse, 43 Renfrew Road, London SE11 4NA',
          note: ''
        },
        {
          date: 'Wednesdays - 6.15pm to 7.45pm',
          place: 'Lambeth Harbour',
          lat: 51.46471586063215,
          lon: -0.10307664417731682,
          address: '245 Coldharbour Lane, London, SW9 8RR',
          note: 'Inquiries-focused meeting'
        },
        // {
        //   headnote: '** Soho Friday meeting is not running until at least Dec 23 **',
        //   date: 'Held on the 2nd and 4th Friday each month - 6:30 pm to 8:00 pm',
        //   place: 'Turning Point',
        //   lat: 51.51152,
        //   lon: -0.13209,
        //   address: '32a Wardour Street, W1D 6QR',
        //   note: ''
        // },
        // {
        //   headnote: '** In person meeting currently suspended **',
        //   date: 'Wednesday – Starts at 6.45pm',
        //   place: 'South Westminster Drug and Alcohol Service',
        //   lat: 51.488075,
        //   lon: -0.140217,
        //   address: '66 Lupus Street, SW1V 3EQ',
        //   note: 'Pimlico or Victoria tube.'
        // },
        // {
        //   headnote: '** In person meeting currently suspended **',
        //   date: 'Friday, 7:00pm to 8:30pm',
        //   place: 'South Westminster Drug and Alcohol Service',
        //   lat: 51.511572,
        //   lon: -0.132142,
        //   address: '32A Wardour St, London W1D 6QT',
        //   note: 'Starts 5th July 2019'
        // }
      ]
    },
    'oxford': {
      city: 'Oxford',
      contacts: [
        {type: 'email', display: 'faekaruna@gmail.com', link: 'mailto:faekaruna@gmail.com'},
        {type: 'facebook', display: 'Recovery Dharma Oxford', link: 'https://www.facebook.com/groups/1792927117632316/'},
      ],
      meetings: [
        {
          date: 'Thursday 7.30 - 9.00pm',
          lat: 51.74366,
          lon: -1.22420,
          place: 'Barracks Lane Community Garden',
          address: 'Barracks Lane, off Cumberland Road, Oxford OX4 2AP',
          // headnote: '** Meeting changed to Thursday **',
          note: 'These peer-led meetings contain guided meditations, a reading from the Dharma Recovery literature and time to reflect and share back on the reading and/or what\'s going on for you. You don\'t have to be  a buddhist or have previous meditation experience to benefit from these practices.'
        }
      ]
    },
    'weston': {
      city: 'Weston-super-Mare',
      contacts: [
        {type: 'facebook', display: 'Recovery Dharma Weston-super-Mare', link: 'https://www.facebook.com/groups/453984241739037/'},
      ],
      meetings: [
        {
          date: 'Thursday 7pm to 8.30pm',
          lat: 51.350805,
          lon: -2.974384,
          place: 'Location: We are with you',
          address: '35 The Boulevard, Weston Super Mare, BS23 1PE',
          // headnote: '** Note change in start time **', // Jan 24
          note: ''
        }
      ]
    }
  };
